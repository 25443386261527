<template>
  <div class="video" v-bind:class="this.viewName">

    <Header></Header>

    <div class="player player-fullscreen" v-bind:class="{ play: status == 'play', paused: status == 'pause', ended: status == 'ended' }">
      <div>

        <div class="infos">

          <div class="debug" v-if="$store.state.debug">
            <div class="duration">Duration: {{ duration }}</div>
            <div class="video">Video: {{ videoID }}</div>
            <div class="video">Viewed Videos: {{ this.$store.state.viewed_videos }}</div>
            <div class="autoplay">Replay: {{ replay }}</div>
            <div class="autoplay">Autoplay: {{ autoplay }}</div>
          </div>

          <h1 class="video-title" v-bind:class="{ show: ( delay == true )} ">Wie kann ich mich freiwillig engagieren?</h1>

          <div class="links" v-bind:class="{ show: (delay == true) }">
            <router-link class="overview-link" to="/uebersicht" v-tooltip="{ content: 'Zum Hauptmenü', placement: 'bottom' }"><img src="@/assets/icon-overview.svg" /></router-link>
            <router-link class="home-link" to="/" v-tooltip="{ content: 'Rundgang neu starten', placement: 'bottom' }"><img src="@/assets/icon-home.svg" /></router-link>
          </div>

          <button class="info-link link-center link-play" v-on:click="PlayVideo" v-bind:class="{ show: (status != 'play' && delay == true && duration == 0) }" v-if="!replay">Jetzt Starten</button>

        </div>

        <vue-plyr-custom ref="plyr" :options="videoOptions">
          <div data-plyr-provider="vimeo" :data-plyr-embed-id="videoID"></div>
        </vue-plyr-custom>

        <div v-bind:class="[ `overview-list`, `overview-list-view`, { show: (duration > 32) || (replay == true && delay) } ]">
          <div class="wrapper" v-for="(linkWrapper_item, index) in linkWrapper" :key="linkWrapper_item.id" v-bind:class="[ linkWrapper_item.class, `wrapper-${index + 1}` ]">
            <slot v-for="(link_item, index) in linkWrapper_item.links">
              <router-link v-bind:class="[ `overview-link`, `link-${index + 1}`, `viewed-${$store.state.viewed_videos.includes(link_item.props.videoID)}`, link_item.class ]" :to="link_item.path" v-if="(duration > (link_item.visibleAt - 1) && (link_item.visibleTo == -1 || duration < link_item.visibleTo + 2)) || replay == true">
                <span v-html="link_item.props.viewTitle"></span>
              </router-link>
            </slot>
          </div>
        </div>

      </div>
    </div>

    <Footer></Footer>

  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'OverviewVideos',
  components: {
    Header,
    Footer
  },

  props: {
    autoplay: {
      type: Boolean,
      default() {
        return this.$store.state.autoplay
      }
    },
    delay_time: {
      type: Number,
      default() {
        return this.$store.state.delay_time
      }
    },

    viewName: {
      type: String
    },
    videoID: {
      type: String,
      default: '0'
    },
    videoOptions: {
        type: Object,
        default() {
            return { autoplay: false, autopause: false, fullscreen: {enabled: false}, controls: [ 'play', 'progress', 'mute', 'volume' ] }
        }
    },
    linkWrapper: {
      type: Array
    },
    endedPush: {
      type: String
    }
  },

  data() {
    return {
      duration: null,
      delay: false,
      status: '',
      replay: false,
      audio: null,
      audiostop: false,
    };
  },

  beforeMount() {
    this.replay = this.$store.state.viewed_videos.includes(this.videoID)
  },

  mounted() {

    if (this.$refs.plyr) {
      if (this.$refs.plyr.player) {
        this.$refs.plyr.player.on('ready', () => this.onReady())
        this.$refs.plyr.player.on('play', () => this.onPlay())
        this.$refs.plyr.player.on('pause', () => this.onPause())
        this.$refs.plyr.player.on('ended', () => this.onEnded())
        this.$refs.plyr.player.on('timeupdate', () => this.videoTimeUpdated())
      }
    }

    this.audiostop = false

    if (this.$store.state.count_video_overview >= 1 && this.replay) {
      if (this.$store.state.count_video_overview == 2) {
        this.audio = new Audio(require('@/assets/audio/es-gibt-noch-viel-zu-entdecken.mp3'))
        if (!this.audiostop) { this.audio.play() }
      } else if (this.$store.state.count_video_overview == 3) {
        this.audio = new Audio(require('@/assets/audio/sieh-dich-ruhig-weiter-um.mp3'))
        if (!this.audiostop) { this.audio.play() }
      } else if (this.$store.state.count_video_overview == 4) {
        this.audio = new Audio(require('@/assets/audio/du-weisst-ja-wie-es-geht.mp3'))
        if (!this.audiostop) { this.audio.play() }
      }
    }

  },

  beforeDestroy() {

    this.$store.commit('add_viewed_video', this.videoID)
    this.$store.commit('increment_video_overview')

    if (this.$refs.plyr) {
      if (this.$refs.plyr.player) {
        this.$refs.plyr.player.stop()
      }
    }

    if (this.audio) {
      this.audio.pause()
      this.audio.currentTime = 0
      this.audiostop = true
    }

  },

  methods: {
    PlayVideo() {
      this.$refs.plyr.player.embed.play()
    },
    onReady() {
      if (this.autoplay && ! this.replay) {
        this.$refs.plyr.player.embed.play()
      }
      setTimeout(() => {
        this.delay = true;
      }, this.delay_time)
    },
    onPlay() {
      this.status = 'play'
    },
    onPause() {
      this.status = 'pause'
    },
    onEnded() {
      this.status = 'ended'

      if (this.endedPush) {
        this.$router.push({ name: this.endedPush })
      }
    },
    videoTimeUpdated: function() {
      if (this.$refs.plyr) {
        if (this.$refs.plyr.player) {
          this.duration = this.$refs.plyr.player.currentTime;
        }
      }
    }
  }

}
</script>