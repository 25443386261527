<template>
  <div class="video" v-bind:class="this.viewName">

    <Header></Header>

    <div class="player player-fullscreen" v-bind:class="{ play: status == 'play', paused: status == 'pause', ended: status == 'ended' }">
      <div>

        <div class="infos">

          <div class="debug" v-if="$store.state.debug">
            <div class="duration">Duration: {{ duration }}</div>
            <div class="video">Video: {{ videoID }}</div>
            <div class="video">Viewed Videos: {{ this.$store.state.viewed_videos }}</div>
            <div class="autoplay">Autoplay: {{ autoplay }}</div>
          </div>

          <button class="info-link link-center link-play" v-on:click="PlayVideo" v-bind:class="{ show: (status != 'play' && delay == true && duration == 0) }">Jetzt Starten</button>

          <div class="wrapper" v-for="(linkWrapper_item, index) in linkWrapper" :key="linkWrapper_item.id" v-bind:class="[ linkWrapper_item.class, `wrapper-${index + 1}` ]">
            <slot v-for="(link_item, index) in linkWrapper_item.links">
              <router-link v-bind:class="[ `info-link`, `link-${index + 1}`, link_item.class, { show: duration > link_item.visibleAt && (link_item.visibleTo == -1 || duration < link_item.visibleTo) }]" :to="link_item.link" v-if="(duration > (link_item.visibleAt - 1) && (link_item.visibleTo == -1 || duration < link_item.visibleTo + 2))">
                <span class="name" v-if="link_item.name != ''" v-html="link_item.name"></span>
               </router-link>
            </slot>
          </div>

        </div>

        <vue-plyr-custom ref="plyr" :options="videoOptions">
          <div data-plyr-provider="vimeo" data-poster="../assets/thumbnails/besuchsdient-001.jpg" :data-plyr-embed-id="videoID"></div>
        </vue-plyr-custom>

      </div>
    </div>

    <Footer></Footer>

  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Intro',
  components: {
    Header,
    Footer
  },

  props: {
    autoplay: {
      type: Boolean,
      default() {
        return this.$store.state.autoplay
      }
    },
    delay_time: {
      type: Number,
      default() {
        return this.$store.state.delay_time
      }
    },

    viewName: {
      type: String
    },
    videoID: {
      type: String,
      default: '0'
    },
    videoOptions: {
      type: Object,
      default() {
        return { autoplay: false, autopause: false, fullscreen: {enabled: false}, controls: [ 'play', 'progress', 'mute', 'volume' ] }
      }
    },

    linkWrapper: {
      type: Array
    },

    endedPush: {
      type: String
    }
  },

  data() {
    return {
      duration: null,
      delay: false,
      status: '',
    };
  },

  beforeMount() {
  },
  afterMount() {
  },

  mounted() {
    if (this.$refs.plyr) {
      if (this.$refs.plyr.player) {
        this.$refs.plyr.player.on('ready', () => this.onReady())
        this.$refs.plyr.player.on('play', () => this.onPlay())
        this.$refs.plyr.player.on('pause', () => this.onPause())
        this.$refs.plyr.player.on('ended', () => this.onEnded())
        this.$refs.plyr.player.on('timeupdate', () => this.videoTimeUpdated())
      }
    }
  },

  beforeDestroy() {

    this.$store.commit('add_viewed_video', this.videoID)
    this.$store.commit('page_transition')

    if (this.$refs.plyr) {
      if (this.$refs.plyr.player) {
        this.$refs.plyr.player.stop()
      }
    }

  },

  methods: {
    PlayVideo() {
      this.$refs.plyr.player.embed.play()
    },
    onReady() {
      if (this.autoplay) {
        this.$refs.plyr.player.embed.play()
      }
      setTimeout(() => {
        this.delay = true;
      }, this.delay_time)
    },
    onPlay() {
      this.status = 'play'
    },
    onPause() {
      this.status = 'pause'
    },
    onEnded() {
      this.status = 'ended'

      if (this.endedPush) {
        this.$router.push({ name: this.endedPush })
      }
    },
    videoTimeUpdated: function() {
      if (this.$refs.plyr) {
        if (this.$refs.plyr.player) {
          this.duration = this.$refs.plyr.player.currentTime;
        }
      }
    }
  }

}
</script>