<template>
  <footer class="footer" id="footer">

    <nav class="footer-menu">
      <ul class="menu-items">
        <li class="menu-item">
          <a href="https://www.freiwilligenzentrum-salzburg.at/informieren/" target="_blank" class="menu-item-link informieren">
            <span><img src="@/assets/icon-footer-informieren.svg" /></span>
            Informieren
          </a>
        </li>
        <li class="menu-item">
          <a href="https://www.freiwilligenzentrum-salzburg.at/engagieren/" target="_blank" class="menu-item-link engagieren">
            <span><img src="@/assets/icon-footer-engagieren.svg" /></span>
            Engagieren
          </a>
        </li>
        <li class="menu-item">
          <a href="https://www.freiwilligenzentrum-salzburg.at/weiterbilden/" target="_blank" class="menu-item-link weiterbilden">
            <span><img src="@/assets/icon-footer-weiterbilden.svg" /></span>
            Weiterbilden
          </a>
        </li>
      </ul>
    </nav>



    <div class="logos">

      <div class="logo land-salzburg">
        <a href="https://www.salzburg.gv.at/" class="sponsor" target="_blank">
          <img src="@/assets/logo-land-salzburg.svg" />
        </a>
      </div>
      <div class="logo caritas">
        <a href="https://www.caritas-salzburg.at/" class="sponsor" target="_blank">
          <img src="@/assets/logo-caritas.svg" />
        </a>
      </div>
      <div class="logo hilfswerk">
        <a href="https://www.hilfswerk.at/salzburg/" class="sponsor" target="_blank">
          <img src="@/assets/logo-hilfswerk.svg" />
        </a>
      </div>
      <div class="logo diakoniewerk">
        <a href="https://www.diakoniewerk.at/wo-wir-sind/diakoniewerk-salzburg" class="sponsor" target="_blank">
          <img src="@/assets/logo-diakoniewerk.svg" />
        </a>
      </div>
      <div class="logo samariterbund">
        <a href="https://www.samariterbund.eu/" class="sponsor" target="_blank">
          <img src="@/assets/logo-samariterbund.png" />
        </a>
      </div>

      <div class="text">
        <span>Unterstützung aus Mitteln des Anerkennungsfonds für Freiwilliges Engagement beim Bundesministerium Soziales, Gesundheit, Pflege und Konsumentenschutz</span>
      </div>

    </div>

    <div class="copyright-wrapper">
      <div class="copyright">
        &copy; 2021 Freiwilligenzentrum Salzburg
      </div>

      <div class="sharing">

        <!-- <span class="sharing-title">
          Teilen:
        </span> -->
        <span class="sharing-content">
          <a class="resp-sharing-button__link" v-tooltip="'Per E-Mail teilen...'" href="mailto:?subject=Freiwilligen%20Zentrum%20Salzburg%3A%20Lassen%20Sie%20sich%20von%20unseren%20Schnuppervideos%20inspirieren%20und%20tauchen%20Sie%20in%20die%20vielf%C3%A4ltige%20Welt%20der%20Freiwilligenarbeit%20ein%20%E2%80%93%20einfach%20und%20unverbindlich%2C%20von%20zu%20Hause%20aus!&amp;body=https%3A%2F%2Fvirtuell.freiwilligenzentrum-salzburg.at%2F" target="_self" rel="noopener" aria-label="">
            <div class="resp-sharing-button resp-sharing-button--email resp-sharing-button--small"><div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22 4H2C.9 4 0 4.9 0 6v12c0 1.1.9 2 2 2h20c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zM7.25 14.43l-3.5 2c-.08.05-.17.07-.25.07-.17 0-.34-.1-.43-.25-.14-.24-.06-.55.18-.68l3.5-2c.24-.14.55-.06.68.18.14.24.06.55-.18.68zm4.75.07c-.1 0-.2-.03-.27-.08l-8.5-5.5c-.23-.15-.3-.46-.15-.7.15-.22.46-.3.7-.14L12 13.4l8.23-5.32c.23-.15.54-.08.7.15.14.23.07.54-.16.7l-8.5 5.5c-.08.04-.17.07-.27.07zm8.93 1.75c-.1.16-.26.25-.43.25-.08 0-.17-.02-.25-.07l-3.5-2c-.24-.13-.32-.44-.18-.68s.44-.32.68-.18l3.5 2c.24.13.32.44.18.68z"/></svg>
              </div>
            </div>
          </a>
          <a class="resp-sharing-button__link" v-tooltip="'Auf Facebook teilen...'" href="https://facebook.com/sharer/sharer.php?u=https%3A%2F%2Fvirtuell.freiwilligenzentrum-salzburg.at%2F" target="_blank" rel="noopener" aria-label="">
            <div class="resp-sharing-button resp-sharing-button--facebook resp-sharing-button--small"><div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.77 7.46H14.5v-1.9c0-.9.6-1.1 1-1.1h3V.5h-4.33C10.24.5 9.5 3.44 9.5 5.32v2.15h-3v4h3v12h5v-12h3.85l.42-4z"/></svg>
              </div>
            </div>
          </a>
          <a class="resp-sharing-button__link" v-tooltip="'Auf Twitter teilen...'" href="https://twitter.com/intent/tweet/?text=Freiwilligen%20Zentrum%20Salzburg%3A%20Lassen%20Sie%20sich%20von%20unseren%20Schnuppervideos%20inspirieren%20und%20tauchen%20Sie%20in%20die%20vielf%C3%A4ltige%20Welt%20der%20Freiwilligenarbeit%20ein%20%E2%80%93%20einfach%20und%20unverbindlich%2C%20von%20zu%20Hause%20aus!&amp;url=https%3A%2F%2Fvirtuell.freiwilligenzentrum-salzburg.at%2F" target="_blank" rel="noopener" aria-label="">
            <div class="resp-sharing-button resp-sharing-button--twitter resp-sharing-button--small"><div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.44 4.83c-.8.37-1.5.38-2.22.02.93-.56.98-.96 1.32-2.02-.88.52-1.86.9-2.9 1.1-.82-.88-2-1.43-3.3-1.43-2.5 0-4.55 2.04-4.55 4.54 0 .36.03.7.1 1.04-3.77-.2-7.12-2-9.36-4.75-.4.67-.6 1.45-.6 2.3 0 1.56.8 2.95 2 3.77-.74-.03-1.44-.23-2.05-.57v.06c0 2.2 1.56 4.03 3.64 4.44-.67.2-1.37.2-2.06.08.58 1.8 2.26 3.12 4.25 3.16C5.78 18.1 3.37 18.74 1 18.46c2 1.3 4.4 2.04 6.97 2.04 8.35 0 12.92-6.92 12.92-12.93 0-.2 0-.4-.02-.6.9-.63 1.96-1.22 2.56-2.14z"/></svg>
              </div>
            </div>
          </a>
          <a class="resp-sharing-button__link" v-tooltip="'Auf LinkedIn teilen...'" href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https%3A%2F%2Fvirtuell.freiwilligenzentrum-salzburg.at%2F&amp;title=Freiwilligen%20Zentrum%20Salzburg%3A%20Lassen%20Sie%20sich%20von%20unseren%20Schnuppervideos%20inspirieren%20und%20tauchen%20Sie%20in%20die%20vielf%C3%A4ltige%20Welt%20der%20Freiwilligenarbeit%20ein%20%E2%80%93%20einfach%20und%20unverbindlich%2C%20von%20zu%20Hause%20aus!&amp;summary=Freiwilligen%20Zentrum%20Salzburg%3A%20Lassen%20Sie%20sich%20von%20unseren%20Schnuppervideos%20inspirieren%20und%20tauchen%20Sie%20in%20die%20vielf%C3%A4ltige%20Welt%20der%20Freiwilligenarbeit%20ein%20%E2%80%93%20einfach%20und%20unverbindlich%2C%20von%20zu%20Hause%20aus!&amp;source=https%3A%2F%2Fvirtuell.freiwilligenzentrum-salzburg.at%2F" target="_blank" rel="noopener" aria-label="">
            <div class="resp-sharing-button resp-sharing-button--linkedin resp-sharing-button--small"><div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M6.5 21.5h-5v-13h5v13zM4 6.5C2.5 6.5 1.5 5.3 1.5 4s1-2.4 2.5-2.4c1.6 0 2.5 1 2.6 2.5 0 1.4-1 2.5-2.6 2.5zm11.5 6c-1 0-2 1-2 2v7h-5v-13h5V10s1.6-1.5 4-1.5c3 0 5 2.2 5 6.3v6.7h-5v-7c0-1-1-2-2-2z"/></svg>
              </div>
            </div>
          </a>
          <a class="resp-sharing-button__link" v-tooltip="'Auf Pinterrest teilen...'" href="https://pinterest.com/pin/create/button/?url=https%3A%2F%2Fvirtuell.freiwilligenzentrum-salzburg.at%2F&amp;media=https%3A%2F%2Fvirtuell.freiwilligenzentrum-salzburg.at%2F&amp;description=Freiwilligen%20Zentrum%20Salzburg%3A%20Lassen%20Sie%20sich%20von%20unseren%20Schnuppervideos%20inspirieren%20und%20tauchen%20Sie%20in%20die%20vielf%C3%A4ltige%20Welt%20der%20Freiwilligenarbeit%20ein%20%E2%80%93%20einfach%20und%20unverbindlich%2C%20von%20zu%20Hause%20aus!" target="_blank" rel="noopener" aria-label="">
            <div class="resp-sharing-button resp-sharing-button--pinterest resp-sharing-button--small"><div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12.14.5C5.86.5 2.7 5 2.7 8.75c0 2.27.86 4.3 2.7 5.05.3.12.57 0 .66-.33l.27-1.06c.1-.32.06-.44-.2-.73-.52-.62-.86-1.44-.86-2.6 0-3.33 2.5-6.32 6.5-6.32 3.55 0 5.5 2.17 5.5 5.07 0 3.8-1.7 7.02-4.2 7.02-1.37 0-2.4-1.14-2.07-2.54.4-1.68 1.16-3.48 1.16-4.7 0-1.07-.58-1.98-1.78-1.98-1.4 0-2.55 1.47-2.55 3.42 0 1.25.43 2.1.43 2.1l-1.7 7.2c-.5 2.13-.08 4.75-.04 5 .02.17.22.2.3.1.14-.18 1.82-2.26 2.4-4.33.16-.58.93-3.63.93-3.63.45.88 1.8 1.65 3.22 1.65 4.25 0 7.13-3.87 7.13-9.05C20.5 4.15 17.18.5 12.14.5z"/></svg>
              </div>
            </div>
          </a>
          <a class="resp-sharing-button__link" v-tooltip="'Per WhatsApp teilen...'" href="whatsapp://send?text=Freiwilligen%20Zentrum%20Salzburg%3A%20Lassen%20Sie%20sich%20von%20unseren%20Schnuppervideos%20inspirieren%20und%20tauchen%20Sie%20in%20die%20vielf%C3%A4ltige%20Welt%20der%20Freiwilligenarbeit%20ein%20%E2%80%93%20einfach%20und%20unverbindlich%2C%20von%20zu%20Hause%20aus!%20https%3A%2F%2Fvirtuell.freiwilligenzentrum-salzburg.at%2F" target="_blank" rel="noopener" aria-label="">
            <div class="resp-sharing-button resp-sharing-button--whatsapp resp-sharing-button--small"><div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20.1 3.9C17.9 1.7 15 .5 12 .5 5.8.5.7 5.6.7 11.9c0 2 .5 3.9 1.5 5.6L.6 23.4l6-1.6c1.6.9 3.5 1.3 5.4 1.3 6.3 0 11.4-5.1 11.4-11.4-.1-2.8-1.2-5.7-3.3-7.8zM12 21.4c-1.7 0-3.3-.5-4.8-1.3l-.4-.2-3.5 1 1-3.4L4 17c-1-1.5-1.4-3.2-1.4-5.1 0-5.2 4.2-9.4 9.4-9.4 2.5 0 4.9 1 6.7 2.8 1.8 1.8 2.8 4.2 2.8 6.7-.1 5.2-4.3 9.4-9.5 9.4zm5.1-7.1c-.3-.1-1.7-.9-1.9-1-.3-.1-.5-.1-.7.1-.2.3-.8 1-.9 1.1-.2.2-.3.2-.6.1s-1.2-.5-2.3-1.4c-.9-.8-1.4-1.7-1.6-2-.2-.3 0-.5.1-.6s.3-.3.4-.5c.2-.1.3-.3.4-.5.1-.2 0-.4 0-.5C10 9 9.3 7.6 9 7c-.1-.4-.4-.3-.5-.3h-.6s-.4.1-.7.3c-.3.3-1 1-1 2.4s1 2.8 1.1 3c.1.2 2 3.1 4.9 4.3.7.3 1.2.5 1.6.6.7.2 1.3.2 1.8.1.6-.1 1.7-.7 1.9-1.3.2-.7.2-1.2.2-1.3-.1-.3-.3-.4-.6-.5z"/></svg>
              </div>
            </div>
          </a>
        </span>

      </div>
    </div>

  </footer>
</template>

<script>
export default {
  name: "Footer",
  props: {
    title: String,
  },
};
</script>
