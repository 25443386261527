import Vue from 'vue'

import VueRouter from 'vue-router'
import Intro from '../views/Intro.vue'
import Overview from '../views/Overview.vue'
import OverviewVideos from '../views/OverviewVideos.vue'
import Video from '../views/Video.vue'
import PathNotFound from '../views/PathNotFound.vue'

Vue.use(VueRouter)

const videoRoutes = [
  {
    path: '/rettungsdienst',
    name: 'Rettungsdienst',
    component: Video,
    props: {
      viewName: 'rettungsdienst',
      viewTitle: 'Rettungs-  und Fahrtendienst',
      videoID: '619754517',
      endedPush: 'Engagieren',
      showBoerse: true,
      BoerseInvolvementID: '80',
    },
    class: '',
    visibleAt: 32,
    visibleTo: -1
  },
  {
    path: '/lernhilfe',
    name: 'Lernhilfe',
    component: Video,
    props: {
      viewName: 'lernhilfe',
      viewTitle: 'Lernhilfe und Wissensvermittlung',
      videoID: '619752932',
      endedPush: 'Engagieren',
      showBoerse: true,
      BoerseInvolvementID: '83',
    },
    class: '',
    visibleAt: 32,
    visibleTo: -1
  },
  {
    path: '/it-hilfe',
    name: 'ITHilfe',
    component: Video,
    props: {
      viewName: 'it-hilfe',
      viewTitle: 'Digitaler Besuchsdienst & <br> IT-Hilfe',
      videoID: '619751784',
      endedPush: 'Engagieren',
      showBoerse: true,
      BoerseInvolvementID: '99',
    },
    class: '',
    visibleAt: 32,
    visibleTo: -1
  },
  {
    path: '/sprachhilfe-integration',
    name: 'SprachhilfeUndIntegration',
    component: Video,
    props: {
      viewName: 'sprachhilfe-integration',
      viewTitle: 'Sprachhilfe und Integration',
      videoID: '619751228',
      endedPush: 'Engagieren',
      showBoerse: true,
      BoerseInvolvementID: '78',
    },
    class: '',
    visibleAt: 32,
    visibleTo: -1
  },
  {
    path: '/wohnungslose-armutsbetroffene',
    name: 'WohnungsloseArmutsbetroffene',
    component: Video,
    props: {
      viewName: 'wohnungslose-armutsbetroffene',
      viewTitle: 'Hilfe für wohnungslose und armutsbetroffene Menschen',
      videoID: '619750146',
      endedPush: 'Engagieren',
      showBoerse: true,
      BoerseInvolvementID: '81',
    },
    class: '',
    visibleAt: 32,
    visibleTo: -1
  },
  {
    path: '/essenaufraedern',
    name: 'EssenAufRaedern',
    component: Video,
    props: {
      viewName: 'essenaufraedern',
      viewTitle: 'Essen auf Rädern',
      videoID: '619748385',
      endedPush: 'Engagieren',
      showBoerse: true,
      BoerseInvolvementID: '87',
    },
    class: '',
    visibleAt: 32,
    visibleTo: -1
  },
  {
    path: '/besuchsdienst',
    name: 'Besuchsdienst',
    component: Video,
    props: {
      viewName: 'besuchsdienst',
      viewTitle: 'Besuchsdienste',
      videoID: '619747642',
      endedPush: 'Engagieren',
      showBoerse: true,
      BoerseInvolvementID: '90',
    },
    class: '',
    visibleAt: 32,
    visibleTo: -1
  },
]

const routes = [

  // Home
  {
    path: '/',
    name: 'Home',
    component: Intro,
    props: {
      viewName: 'home',
      videoID: '619756092',
      endedPush: 'Intro1',
      linkWrapper: [
        {
          class: '',
          links: [
            { name: 'Überspringen', link: '/intro', class: 'link-bottom-right skip', visibleAt: 1, visibleTo: 7.5 },
            // add logo
            { name: '', link: 'https://www.freiwilligenzentrum-salzburg.at/', class: 'link-image link-logo', visibleAt: 10.5, visibleTo: -1 },
            { name: 'Jetzt virtuellen Rundgang starten', link: '/intro', class: 'link-center link-home link-large link-primary', visibleAt: 10.5, visibleTo: -1 },
          ]
        },
      ]

    }
  },

  // Intros
  {
    path: '/intro',
    name: 'Intro1',
    component: Intro,
    props: {
      viewName: 'intro1',
      videoID: '619796477',
      linkWrapper: [
        {
          class: '',
          links: [
            { name: 'Klicke hier', link: '/weiter', class: 'link-top-left-25 link-large link-primary', visibleAt: 29.5, visibleTo: -1 }
          ]
        },
      ]
    }
  },
  {
    path: '/weiter',
    name: 'Intro2',
    component: Intro,
    props: {
      viewName: 'intro2',
      videoID: '619796995',
      linkWrapper: [
        {
          class: 'wrapper-center-center',
          links: [
            { name: 'Ich bin bereit', link: '/uebersicht', class: 'link-large link-primary', visibleAt: 12, visibleTo: -1 },
            { name: 'Ich bin nicht bereit', link: '/nicht-bereit', class: 'link-large link-primary link-light', visibleAt: 14, visibleTo: -1 }
          ]
        },
      ]
    }
  },

  // Not Ready
  {
    path: '/nicht-bereit',
    name: 'NotReady',
    component: Intro,
    props: {
      viewName: 'notready',
      videoID: '629069175',
      videoOptions: {autoplay: true, autopause: false, volume: 0, muted: true, loop: {active: true}, fullscreen: {enabled: false}, controls: []},
      linkWrapper: [
        {
          class: '',
          links: [
            { name: 'Doch bereit', link: '/uebersicht', class: 'link-primary link-center link-large', visibleAt: 1.5, visibleTo: -1 }
          ]
        },
      ]
    }
  },

  // Overview
  {
    path: '/uebersicht',
    name: 'Overview',
    component: Overview,
    props: {
      linkWrapper: [
        {
          class: '',
          links: [
            { name: 'Wie kann ich mich engagieren?', link: '/engagieren', class: 'large' },
            { name: 'Wozu freiwillig engagieren?', link: '/wozu', class: '' },
            { name: 'Wer ist das Freiwilligen-<br>zentrum?', link: '/wer', class: '' },
            { name: 'Wie kann ich mich weiterbilden?', link: '/fortbildung', class: '' },
          ]
        },
      ]
    }
  },

  // Wie engagieren?
  {
    path: '/engagieren',
    name: 'Engagieren',
    component: OverviewVideos,
    props: {
      viewName: 'engagieren',
      videoID: '619757538',
      linkWrapper: [
        {
          class: 'wrapper-center-center',
          links: videoRoutes
        },
      ]
    }
  },

  // Wozu freiwillig engagieren?
  {
    path: '/wozu',
    name: 'Wozu',
    component: Video,
    props: {
      viewName: 'wozu',
      viewTitle: 'Wozu freiwillig engagieren?',
      videoID: '841918805',
      endedPush: 'Overview',
      endedURL: '/uebersicht',
      showBackLink: false
    }
  },
  // Wer?
  {
    path: '/wer',
    name: 'Wer',
    component: Video,
    props: {
      viewName: 'wer',
      viewTitle: 'Wer ist das Freiwilligenzentrum?',
      videoID: '619756543',
      endedPush: 'Overview',
      endedURL: '/uebersicht',
      showBackLink: false
    }
  },
  // Fortbildung?
  {
    path: '/fortbildung',
    name: 'Fortbildung',
    component: Video,
    props: {
      viewName: 'fortbildung',
      viewTitle: 'Wie kann ich mich weiterbilden?',
      videoID: '619757844',
      endedPush: 'Overview',
      endedURL: '/uebersicht',
      showBackLink: false
    }
  },

]

// add video routes
videoRoutes.forEach(videoRoute => routes.push(videoRoute));

routes.push(
  {
    path: '/:pathMatch(.*)*', component: PathNotFound // 404
  }
);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
