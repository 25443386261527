import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    debug: false,
    autoplay: true,
    delay_time: 1000,
    count_overview: 0,
    count_video_overview: 0,
    viewed_videos: Array(),
  },
  mutations: {
    increment_overview (state) {
      state.count_overview++
    },
    increment_video_overview (state) {
      state.count_video_overview++
    },
    add_viewed_video (state, video) {
      if (! state.viewed_videos.includes(video)) {
        state.viewed_videos.push(video);
      }
    },
    page_transition () {
    }
  },
  actions: {
  },
  modules: {
  }
})
