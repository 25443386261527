<template>

  <header id="header">
    <!-- <router-link class="logo logo-link" to="/"><img src="@/assets/logo.svg" /></router-link> -->

    <span class="website-link-wrapper">
      <a href="https://www.freiwilligenzentrum-salzburg.at/" target="_blank" class="website-link">Zur Website</a>
    </span>

    <a href="https://www.freiwilligenzentrum-salzburg.at/" target="_blank" class="logo logo-link"><img src="@/assets/logo.svg" /></a>

    <ul class="social-icons">
      <li class="social-icons-item">
        <a href="https://www.facebook.com/fwzsalzburg/" target="_blank" rel="nofollow" v-tooltip="{ content: 'Zu unserem Facebook-Profil', placement: 'bottom' }">
          <span class="icon">
            <svg class="facebook" xmlns="http://www.w3.org/2000/svg" width="12.703" height="23.611" viewBox="0 0 12.703 23.611"><path d="M13.257,12.679V8.17h3.637V5.9a5.934,5.934,0,0,1,1.593-4.179A5.068,5.068,0,0,1,22.347,0H25.96V4.51H22.347a.8.8,0,0,0-.637.39,1.582,1.582,0,0,0-.283.956V8.169H25.96v4.509H21.426V23.611H16.893V12.679Z" transform="translate(-13.257)"></path></svg>
          </span>
        </a>
      </li>
      <li class="social-icons-item">
        <a href="https://www.instagram.com/freiwilligenzentrum_salzburg/" target="_blank" rel="nofollow" v-tooltip="{ content: 'Zu unserem Instagram-Profil', placement: 'bottom' }">
          <span class="icon">
            <svg class="instagram" xmlns="http://www.w3.org/2000/svg" width="25.748" height="25.748" viewBox="0 0 25.748 25.748"><path d="M12.874,0C9.376,0,8.94.016,7.567.077a9.493,9.493,0,0,0-3.125.6A6.3,6.3,0,0,0,2.161,2.161,6.281,6.281,0,0,0,.676,4.441a9.465,9.465,0,0,0-.6,3.125C.013,8.94,0,9.376,0,12.874s.016,3.934.077,5.307a9.5,9.5,0,0,0,.6,3.125,6.313,6.313,0,0,0,1.485,2.281,6.3,6.3,0,0,0,2.281,1.485,9.5,9.5,0,0,0,3.125.6c1.373.064,1.81.077,5.307.077s3.934-.016,5.307-.077a9.527,9.527,0,0,0,3.125-.6,6.579,6.579,0,0,0,3.766-3.766,9.5,9.5,0,0,0,.6-3.125c.064-1.373.077-1.81.077-5.307s-.016-3.934-.077-5.307a9.521,9.521,0,0,0-.6-3.125,6.318,6.318,0,0,0-1.485-2.281A6.273,6.273,0,0,0,21.306.676a9.471,9.471,0,0,0-3.125-.6C16.808.013,16.371,0,12.874,0Zm0,2.317c3.436,0,3.846.017,5.2.076a7.093,7.093,0,0,1,2.389.445A4.237,4.237,0,0,1,22.91,5.281a7.108,7.108,0,0,1,.443,2.389c.061,1.358.075,1.766.075,5.2s-.016,3.846-.079,5.2a7.245,7.245,0,0,1-.452,2.389,4.088,4.088,0,0,1-.964,1.483,4.016,4.016,0,0,1-1.48.961,7.16,7.16,0,0,1-2.4.443c-1.367.061-1.769.075-5.213.075s-3.847-.016-5.213-.079a7.3,7.3,0,0,1-2.4-.452,3.987,3.987,0,0,1-1.479-.964,3.909,3.909,0,0,1-.966-1.48,7.306,7.306,0,0,1-.451-2.4c-.048-1.352-.065-1.769-.065-5.2s.017-3.847.065-5.215a7.3,7.3,0,0,1,.451-2.4,3.816,3.816,0,0,1,.966-1.482A3.808,3.808,0,0,1,5.23,2.8a7.125,7.125,0,0,1,2.383-.452c1.368-.048,1.77-.064,5.213-.064l.048.032Zm0,3.946a6.611,6.611,0,1,0,6.611,6.611A6.61,6.61,0,0,0,12.874,6.263Zm0,10.9a4.291,4.291,0,1,1,4.291-4.291A4.29,4.29,0,0,1,12.874,17.165ZM21.291,6a1.545,1.545,0,1,1-1.545-1.544A1.546,1.546,0,0,1,21.291,6Z"></path></svg>
          </span>
        </a>
      </li>
    </ul>

  </header>

</template>

<script>
export default {
  name: 'Header'
}
</script>