import Vue from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'

import VuePlyrCustom from '../vendor/vue-plyr-custom'
import '../vendor/vue-plyr-custom/dist/vue-plyr-custom.css'

import VTooltip from 'v-tooltip'
import VueScrollTo from 'vue-scrollto'
import VuePageTransition from 'vue-page-transition'

Vue.config.productionTip = false

Vue.use(VTooltip)
Vue.use(VueScrollTo)
Vue.use(VuePageTransition)
Vue.use(VuePlyrCustom, { plyr: {} })

// Plugin
import CursorFx from '@luxdamore/vue-cursor-fx'
import '@luxdamore/vue-cursor-fx/dist/CursorFx.css'

 // Install
Vue.use(
  CursorFx
)

new Vue({
  router,
  store,
  render: h => h(App),
  data: {
    soundbit_sounds: [
      new Audio(require('@/assets/audio/soundbits/Popup_melo_1.mp3')),
      new Audio(require('@/assets/audio/soundbits/Popup_melo_2.mp3')),
      new Audio(require('@/assets/audio/soundbits/Popup_melo_3.mp3')),
      new Audio(require('@/assets/audio/soundbits/Popup_melo_4.mp3')),
      new Audio(require('@/assets/audio/soundbits/Popup_Standard_1.mp3'))
    ],
    soundbit_sound_duration: 0.35
  },
  methods: {
    playSoundbit(index) {
      this.soundbit_sounds[index].volume = 0.5
      this.soundbit_sounds[index].play()
    }
  }
}).$mount('#app')