<template>
  <div class="video" v-bind:class="this.viewName">

    <Header></Header>

    <div class="player player-fullscreen" v-bind:class="{ play: status == 'play', paused: status == 'pause', ended: status == 'ended' }">
      <div>

        <div class="infos">

          <div class="debug" v-if="$store.state.debug">
            <div class="duration">Duration: {{ duration }}</div>
            <div class="video">Video: {{ videoID }}</div>
          </div>

          <h1 class="video-title" v-bind:class="{ show: ( delay == true && duration >= 0.5 && duration <= 10) }"><span v-html="viewTitle"></span></h1>

          <div class="links" v-bind:class="{ show: (delay == true) }">
            <router-link class="back-link" v-if="showBackLink" :to="endedURL" v-tooltip="{ content: 'Zurück', placement: 'bottom' }"><img src="@/assets/icon-slider-arrow-prev.svg" /></router-link>
            <router-link class="overview-link" to="/uebersicht" v-tooltip="{ content: 'Zum Hauptmenü', placement: 'bottom' }"><img src="@/assets/icon-overview.svg" /></router-link>
            <router-link class="home-link" to="/" v-tooltip="{ content: 'Rundgang neu starten', placement: 'bottom' }"><img src="@/assets/icon-home.svg" /></router-link>
          </div>

          <button class="info-link link-center link-play" v-on:click="PlayVideo" v-bind:class="{ show: (status != 'play' && delay == true && duration == 0) }">Jetzt Starten</button>

          <div class="info-link-container" v-if="showBoerseLink" v-bind:class="{ show: showBoerseLink && status == 'ended' }">
            <button class="info-link link-scroll" v-scroll-to="'#boerse'">
              Hier geht es zu den offenen Stellen
              <img src="@/assets/icon-slider-arrow-prev.svg" class="link-scroll-img" />
            </button>
            <router-link class="info-link no-style" v-if="showBackLink" :to="endedURL">Weitere Freiwilligenprojekte entdecken</router-link>
          </div>

        </div>

        <vue-plyr-custom ref="plyr" :options="videoOptions">
          <div data-plyr-provider="vimeo" :data-plyr-embed-id="videoID"></div>
        </vue-plyr-custom>

      </div>
    </div>

    <div class="boerse-wrapper" v-if="showBoerse">
    <div class="boerse-inner" id="boerse" v-if="boerse">

      <div class="boerse-title">
        <h3>Aktuelle Engagementmöglichkeiten</h3>
        <h4>im Bereich <span v-html="viewTitle"></span></h4>
      </div>

      <div class="items">

        <slot v-for="(boerse_item) in boerse">

          <a :key="boerse_item.id" :href="boerse_item.link" target="_blank">
            <h4 class="title"><span v-html="boerse_item.title.rendered"></span></h4>
            <div class="organisation"><span v-html="boerse_item.meta._company_name"></span></div>
            <div class="location"><span v-html="boerse_item.meta._job_location"></span></div>
          </a>

        </slot>

        <a href="https://www.freiwilligenzentrum-salzburg.at/engagieren/" class="highlight" target="_blank">
          <h4 class="title">Nichts Passendes dabei?</h4>
          <h4 class="title-alternative">Alle offenen Stellen in der Freiwilligenbörse entdecken</h4>
        </a>

      </div>

      <div>

      </div>

    </div>
    </div>

    <Footer></Footer>

  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import axios from 'axios'

export default {
  name: 'Intro',
  components: {
    Header,
    Footer
  },

  props: {
    autoplay: {
      type: Boolean,
      default() {
        return this.$store.state.autoplay
      }
    },
    delay_time: {
      type: Number,
      default() {
        return this.$store.state.delay_time
      }
    },

    viewName: {
      type: String
    },
    viewTitle: {
      type: String
    },
    videoID: {
      type: String,
      default: '0'
    },
    videoOptions: {
        type: Object,
        default() {
            return { autoplay: false, autopause: false, fullscreen: {enabled: true}, controls: [ 'play', 'progress', 'mute', 'volume', 'fullscreen' ] }
        }
    },
    endedPush: {
      type: String,
      default: 'Engagieren'
    },
    endedURL: {
      type: String,
      default: '/engagieren'
    },

    showBackLink: {
      type: Boolean,
      default: true
    },

    showBoerse: {
      type: Boolean,
      default: false
    },
    BoerseSlug: {
      type: String,
      default: ''
    },
    BoerseInvolvementID: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      duration: null,
      delay: false,
      status: '',
      boerse: null,
      showBoerseLink: false,
      axiosParams: null,
    };
  },

  mounted() {
    if (this.$refs.plyr) {
      if (this.$refs.plyr.player) {
        this.$refs.plyr.player.on('ready', () => this.onReady())
        this.$refs.plyr.player.on('play', () => this.onPlay())
        this.$refs.plyr.player.on('pause', () => this.onPause())
        this.$refs.plyr.player.on('ended', () => this.onEnded())
        this.$refs.plyr.player.on('timeupdate', () => this.videoTimeUpdated())
      }
    }

    if (this.showBoerse) {
      // https://www.freiwilligenzentrum-salzburg.at/wp-json/wp/v2/job-listings
      this.axiosParams = new URLSearchParams()
      if (this.BoerseSlug != '') this.axiosParams.append('search', this.BoerseSlug)
      if (this.BoerseInvolvementID != '') this.axiosParams.append('job-involvements', this.BoerseInvolvementID)

      axios
        .get('https://www.freiwilligenzentrum-salzburg.at/wp-json/wp/v2/job-listings', {
          params: this.axiosParams
        })
        .then(response => (this.boerse = response.data))
    }
  },

  beforeDestroy() {

    this.$store.commit('add_viewed_video', this.videoID)
    this.$store.commit('page_transition')

    if (this.$refs.plyr) {
      if (this.$refs.plyr.player) {
        this.$refs.plyr.player.stop()
      }
    }

  },

  methods: {
    PlayVideo() {
      this.$refs.plyr.player.embed.play()
    },
    onReady() {
      if (this.autoplay) {
        this.$refs.plyr.player.embed.play()
      }

      setTimeout(() => {
        this.delay = true;
      }, this.delay_time)
    },
    onPlay() {
      this.status = 'play'
    },
    onPause() {
      this.status = 'pause'
    },
    onEnded() {
      this.status = 'ended'

      this.$refs.plyr.player.fullscreen.exit()

      if (this.endedPush) {
        if (!this.showBoerse) {
          this.$router.push({ name: this.endedPush })
        } else {
          this.showBoerseLink = true
        }
      }
    },
    videoTimeUpdated: function() {
      if (this.$refs.plyr) {
        if (this.$refs.plyr.player) {
          this.duration = this.$refs.plyr.player.currentTime;
        }
      }
    }
  }

}
</script>