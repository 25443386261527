<template>
  <div class="overview overview-page">

    <Header></Header>

    <div class="player player-fullscreen" v-bind:class="{ play: status == 'play', paused: status == 'pause', ended: status == 'ended' }">
      <div>

        <div class="infos">

          <h1 class="video-title" v-bind:class="{ show: ( delay == true )} ">Virtueller Rundgang</h1>

          <div class="links" v-bind:class="{ show: (delay == true) }">
            <router-link class="home-link" to="/" v-tooltip="{ content: 'Rundgang neu starten', placement: 'bottom' }"><img src="@/assets/icon-home.svg" /></router-link>
          </div>

        </div>

        <div class="plyr plyr--full-ui plyr--video plyr--vimeo plyr--paused plyr--stopped">
          <div class="plyr__video-wrapper plyr__video-embed" style="aspect-ratio: 16 / 9;">
            <div class="plyr__poster"></div>
          </div>
        </div>

        <div v-bind:class="[ `overview-list`, `overview-view`, { show: delay } ]">
          <div class="wrapper" v-for="(linkWrapper_item, index) in linkWrapper" :key="linkWrapper_item.id" v-bind:class="[ linkWrapper_item.class, `wrapper-${index + 1}` ]">
            <slot v-for="(link_item, index) in linkWrapper_item.links">
              <router-link v-bind:class="[ `overview-link`, `link-${index + 1}`, link_item.class ]" :to="link_item.link">
                <span v-html="link_item.name"></span>
              </router-link>
            </slot>
          </div>
        </div>

      </div>
    </div>

    <Footer></Footer>

  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Overview',
  components: {
    Header,
    Footer
  },

  props: {
    linkWrapper: {
      type: Array
    },
    delay_time: {
      type: Number,
      default() {
        return this.$store.state.delay_time
      }
    },
  },

  data() {
    return {
      duration: null,
      status: '',
      delay: false,
      audio: null,
      audiostop: false,
    };
  },

  mounted() {

    setTimeout(() => {
      this.delay = true;
    }, this.delay_time)

    this.audiostop = false

    if (this.$store.state.count_overview == 0) {
      this.audio = new Audio(require('@/assets/audio/das-ist-das-hauptmenue.mp3'))
      if (!this.audiostop) { this.audio.play() }
    } else if (this.$store.state.count_overview == 1) {
      this.audio = new Audio(require('@/assets/audio/einfach-weiterklicken.mp3'))
      if (!this.audiostop) { this.audio.play() }
    }

  },

  beforeDestroy() {

    this.$store.commit('increment_overview')

    if (this.audio) {
      this.audio.pause()
      this.audio.currentTime = 0
      this.audiostop = true
    }

  },

  methods: {
    onReady() {
    }
  }

}
</script>